import React, { useState } from 'react'
import Index from '../../../content/accordion/sluzby'
import './styles.css'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { Menu, X } from 'react-feather'

const NavigationBarPreview = ({ logo }) => {
  const [active, setActive] = useState(false)
  const {
    dane,
    mzdy,
    zahranici,
    firemniPoradenstvi,
    ucetnictvi,
    znaleckePosudky
  } = Index
  return (
    <header>
      <div className="container-Nav">
        <nav className={active ? 'active' : ''}>
          <div onClick={() => setActive(!active)} className="menu-icons">
            <X className="ion-md-close" />
            <Menu className="ion-md-menu" />
          </div>
          <Link to="/">
            <img className="logo" src={logo.fluid.src} alt="logo" />
          </Link>
          <ul className="nav-list">
            <li className="move-left">
              <div className="btn">
                <a
                  className="black-button"
                  href="mailto:&#101;&#107;&#112;&#64;&#101;&#107;&#112;&#46;&#99;&#122;"
                >
                  &#101;&#107;&#112;&#64;&#101;&#107;&#112;&#46;&#99;&#122;
                </a>
              </div>

              <div className="btn">
                <a className="black-button" href="tel:+420273139330">
                  +420 273 139 330
                </a>
              </div>
            </li>
            <li>
              <Link className="uppercase" to="/sluzby/">
                Služby
                <i className="icon ion-md-arrow-dropdown"></i>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to={`/sluzby/${dane.slug}`}>
                    {dane.title}
                    <i className="icon ion-md-arrow-dropdown"></i>
                  </Link>
                  <ul className="sub-menu">
                    {dane.links.map(item => (
                      <li key={item.slug}>
                        <Link to={`/sluzby/${dane.slug + item.slug}`}>
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <Link to={`/sluzby/${mzdy.slug}`}>
                    {mzdy.title}
                    <i className="icon ion-md-arrow-dropdown"></i>
                  </Link>
                  <ul className="sub-menu">
                    {mzdy.links.map(item => (
                      <li key={item.slug}>
                        <Link to={`/sluzby/${mzdy.slug + item.slug}`}>
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <Link to={`/sluzby/${zahranici.slug}`}>
                    {zahranici.title}
                    <i className="icon ion-md-arrow-dropdown"></i>
                  </Link>
                  <ul className="sub-menu">
                    {zahranici.links.map(item => (
                      <li key={item.slug}>
                        <Link to={`/sluzby/${zahranici.slug + item.slug}`}>
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <Link to={`/sluzby/${firemniPoradenstvi.slug}`}>
                    {firemniPoradenstvi.title}
                    <i className="icon ion-md-arrow-dropdown"></i>
                  </Link>
                  <ul className="sub-menu">
                    {firemniPoradenstvi.links.map(item => (
                      <li key={item.slug}>
                        <Link
                          to={`/sluzby/${firemniPoradenstvi.slug + item.slug}`}
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <Link to={`/sluzby/${ucetnictvi.slug}`}>
                    {ucetnictvi.title}
                    <i className="icon ion-md-arrow-dropdown"></i>
                  </Link>
                  <ul className="sub-menu">
                    {ucetnictvi.links.map(item => (
                      <li key={item.slug}>
                        <Link to={`/sluzby/${ucetnictvi.slug + item.slug}`}>
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <Link to={`/sluzby/${znaleckePosudky.slug}`}>
                    {znaleckePosudky.title}
                    <i className="icon ion-md-arrow-dropdown"></i>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link className="uppercase" to="/o-nas/">
                O nás
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/o-nas/nas-tym/">Náš tým</Link>
                </li>
                <li>
                  <Link to="/o-nas/prednasky-konference/">
                    Přednášky a konference
                  </Link>
                </li>
                <li>
                  <Link to="/o-nas/pripadove-studie/">Případové studie</Link>
                </li>
                <li>
                  <Link to="/o-nas/#reference">Reference</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link className="uppercase" to="/kariera/">
                Kariéra
              </Link>
            </li>
            <li>
              <Link className="uppercase" to="/novinky-blog/">
                Novinky a blog
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/novinky-blog/">Vše</Link>
                </li>
                <li>
                  <Link to="/novinky-blog/novinky/">Novinky</Link>
                </li>
                <li>
                  <Link to="/novinky-blog/blog/">Blog</Link>
                </li>
                <li>
                  <Link to="/novinky-blog/ekpruvodce/">Newsletter</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link className="uppercase" to="/kontakt">
                Kontakt
              </Link>
            </li>
            <li id="">
              <Link className="uppercase" id="languageMenu" to="/">
                CZ
              </Link>
              <ul className="sub-menu" style={{ width: '80px' }}>
                <li>
                  <Link to="/en/">EN</Link>
                </li>
                <li>
                  <Link to="/de/">DE</Link>
                </li>
              </ul>
            </li>
            <li>
              <div className="move-top">
                <div className="btn-small">
                  <a
                    className="black-button"
                    href="mailto:&#101;&#107;&#112;&#64;&#101;&#107;&#112;&#46;&#99;&#122;"
                  >
                    &#101;&#107;&#112;&#64;&#101;&#107;&#112;&#46;&#99;&#122;
                  </a>
                </div>
              </div>
              <div className="move-top">
                <div className="btn-small">
                  <a className="black-button" href="tel:+420273139330">
                    +420 273 139 330
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

const NavigationBar = ({ location }) => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "hp-logo.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            src
          }
        }
      }
    }
  `)
  return (
    <NavigationBarPreview logo={logo.childImageSharp} location={location} />
  )
}

export default NavigationBar
